import React, { useEffect, useState } from 'react'
import { Avatar, chain, Item, ItemSkeleton, Text, Token, VStack } from '@revolut/ui-kit'

import {
  getPayGroupTimelineTableRequests,
  useGetPayRollChangeDetails,
} from '@src/api/payrollV2'
import ButtonFilter from '@src/components/ButtonFilters/ButtonFilter'
import SideBar from '@src/components/SideBar/SideBar'
import { useTable } from '@src/components/Table/hooks'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import { selectorKeys } from '@src/constants/api'
import {
  payCycleTimelineCategoryColumn,
  payCycleTimelineEffectiveDateTimeColumn,
  payCycleTimelineEmployeeNameColumn,
  payCycleTimelineDomainColumn,
} from '@src/constants/columns/payCycleTimeline'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { PayrollTimelineEventInterface } from '@src/interfaces/payrollV2'
import { getAvatarUrl, getInitials } from '@src/utils/employees'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'

import { CommonTabProps } from '../common'
import { DetailsSidebarContent } from '../common/DetailsSidebarContent'
import { EVENT_TYPES_OPTIONS } from './common'

const getRow = (
  onClick: (id: number) => void,
): RowInterface<PayrollTimelineEventInterface> => ({
  linkToForm: data => onClick(data.content.id),
  cells: [
    {
      ...payCycleTimelineEmployeeNameColumn,
      width: 420,
    },
    {
      ...payCycleTimelineEffectiveDateTimeColumn,
      width: 150,
    },
    {
      ...payCycleTimelineCategoryColumn,
      width: 120,
    },
    {
      ...payCycleTimelineDomainColumn,
      width: 300,
    },
  ],
})

export const PayCycleTabChanges = ({ data, timelineFilters }: CommonTabProps) => {
  const [selectedChangeId, setSelectedChangeId] = useState<number>()

  const {
    filterByDatesRange,
    datesRangeFilter,
    filterByEventType,
    eventTypesFilter,
    eventTypesFilterStr,
    setDatesRangeFilter,
    setEventTypesFilter,
  } = timelineFilters

  const currentFilters = [...filterByDatesRange, ...filterByEventType]

  useEffect(() => {
    table.onFilterChange(currentFilters)
  }, [datesRangeFilter[0]?.id, datesRangeFilter[1]?.id, eventTypesFilterStr])

  const {
    data: changeDetails,
    isLoading: isLoadingChangeDetails,
    isError: isChangeDetailsError,
  } = useGetPayRollChangeDetails(selectedChangeId)
  const selectedEmployee = changeDetails?.employee

  const table = useTable<PayrollTimelineEventInterface>(
    getPayGroupTimelineTableRequests(data.pay_group.id),
    currentFilters,
    undefined,
    { disableQuery: true },
  )

  return (
    <>
      <Table.Widget>
        <Table.Widget.Filters>
          <ButtonFilter
            title="Dates range"
            selector={selectorKeys.none}
            type="DateRange"
            value={datesRangeFilter}
            onChange={dates => {
              if (dates) {
                setDatesRangeFilter(dates)
              }
            }}
          />
          <ButtonFilter
            title="Event types"
            selector={() =>
              Promise.resolve({
                options: EVENT_TYPES_OPTIONS,
              })
            }
            type="MultiSelect"
            value={eventTypesFilter}
            onChange={eventTypes => {
              if (eventTypes) {
                setEventTypesFilter(eventTypes)
              }
            }}
          />
        </Table.Widget.Filters>
        <Table.Widget.Table>
          <AdjustableTable
            hideCount
            name={TableNames.PayCycleEmployeeReports}
            row={getRow(id => setSelectedChangeId(id))}
            useWindowScroll
            emptyState={<EmptyTableRaw title="No changes found" />}
            {...table}
          />
        </Table.Widget.Table>
      </Table.Widget>
      <SideBar
        title=""
        variant="wide"
        sideProps={{ resizable: true }}
        isOpen={!!selectedChangeId}
        onClose={() => setSelectedChangeId(undefined)}
      >
        {isLoadingChangeDetails ? (
          <VStack space="s-16">
            <ItemSkeleton />
            <ItemSkeleton />
            <ItemSkeleton />
          </VStack>
        ) : (
          <VStack space="s-20">
            {selectedEmployee ? (
              <Item>
                <Item.Avatar>
                  <Avatar
                    image={getAvatarUrl(selectedEmployee.avatar)}
                    label={getInitials(selectedEmployee.full_name || '')}
                  />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>
                    <Text>{selectedEmployee.full_name}</Text>
                  </Item.Title>
                  {!!(selectedEmployee.job_title || selectedEmployee.seniority) && (
                    <Item.Description>
                      <Text color={Token.color.greyTone50}>
                        {chain(
                          selectedEmployee.job_title,
                          selectedEmployee.seniority?.name,
                        )}
                      </Text>
                    </Item.Description>
                  )}
                </Item.Content>
              </Item>
            ) : (
              <ItemSkeleton />
            )}
            <DetailsSidebarContent
              isLoading={isLoadingChangeDetails}
              isError={isChangeDetailsError}
              timelineChanges={changeDetails ? [changeDetails] : undefined}
              onClose={() => setSelectedChangeId(undefined)}
            />
          </VStack>
        )}
      </SideBar>
    </>
  )
}
