import React from 'react'
import isEmpty from 'lodash/isEmpty'
import toLower from 'lodash/toLower'
import pluralize from 'pluralize'
import { Flex, HStack, Icon, Text, Token, VStack } from '@revolut/ui-kit'

import {
  PayrollTimelineChange,
  PayrollTimelineChangeContractFull,
  PayrollTimelineChangeInterface,
  PayrollTimelineChangeNoticePeriodAfterProbation,
  PayrollTimelineChangeNoticePeriodDuringProbation,
  PayrollTimelineChangeSalary,
  PayrollTimelineChangeSeniority,
  PayrollTimelineChangeSignOnBonus,
  PayrollTimelineChangeTimeOffRequest,
} from '@src/interfaces/payrollV2'
import { formatDate, formatMoneyWithCode, formatWithoutTimezone } from '@src/utils/format'
import {
  MultipleChangesCellWrapper,
  SingleChangeCellWrapper,
} from './DetailsSidebarContent/DomainChangesWidget'

type AddedValueWidgetProps = {
  change: PayrollTimelineChangeInterface
  value: string
}
const AddedValueWidget = ({ change, value }: AddedValueWidgetProps) => {
  return (
    <SingleChangeCellWrapper change={change}>
      <HStack align="center" space="s-8">
        <Icon name="PlusCircle" color={Token.color.blue} size={15} />
        <Text>{value}</Text>
      </HStack>
    </SingleChangeCellWrapper>
  )
}

type UpdatedValueWidgetProps = {
  change: PayrollTimelineChangeInterface
  from: string
  to: string
}
const UpdatedValueWidget = ({ change, from, to }: UpdatedValueWidgetProps) => {
  return (
    <MultipleChangesCellWrapper change={change}>
      <VStack space="s-16" py="s-8">
        <HStack align="center" space="s-8">
          <Text color={Token.color.greyTone50} fontWeight={500}>
            Updated to:
          </Text>
          <Text>{to}</Text>
        </HStack>
        <HStack align="center" space="s-8">
          <Text color={Token.color.greyTone50} fontWeight={500}>
            Previous:
          </Text>
          <Text>{from}</Text>
        </HStack>
      </VStack>
    </MultipleChangesCellWrapper>
  )
}

const assertFields = <T,>(
  data: PayrollTimelineChange | null | undefined,
  fields: Array<keyof T>,
): boolean => {
  if (!data) {
    return false
  }
  for (const field of fields) {
    if (!(field in data)) {
      return false
    }
  }
  return true
}

const assertSalaryDomainFields = (
  data: PayrollTimelineChange | null | undefined,
): data is PayrollTimelineChangeSalary => {
  return assertFields<PayrollTimelineChangeSalary>(data, [
    'salary_amount',
    'salary_currency',
    'salary_time_unit',
    'salary_payment_frequency',
  ])
}

const getSalaryValue = (data?: PayrollTimelineChangeSalary) => {
  if (
    !data ||
    !assertFields<PayrollTimelineChangeSalary>(data, [
      'salary_amount',
      'salary_currency',
      'salary_time_unit',
    ])
  ) {
    return ''
  }
  return formatMoneyWithCode(data.salary_amount, data.salary_currency)
}

const getSalaryFrequency = (salaryFrequency?: string) => {
  if (salaryFrequency == null) {
    return ''
  }
  return toLower(salaryFrequency)
}

const getSalaryString = (data?: PayrollTimelineChange | null) => {
  if (!assertSalaryDomainFields(data)) {
    return undefined
  }
  const salaryWithCurrency = getSalaryValue(data)
  const salaryFrequency = getSalaryFrequency(data?.salary_payment_frequency)

  if (salaryWithCurrency && salaryFrequency) {
    return `${salaryWithCurrency} ${salaryFrequency}`
  }
  return salaryWithCurrency
}

export const formatSalaryDomain = (change: PayrollTimelineChangeInterface) => {
  const from = getSalaryString(change.from_value)
  const to = getSalaryString(change.to_value)

  if (!from && to) {
    return <AddedValueWidget change={change} value={to} />
  }
  if (from && to) {
    return <UpdatedValueWidget change={change} from={from} to={to} />
  }
  return undefined
}

const assertNoticePeriodDuringProbationFields = (
  data: PayrollTimelineChange | null,
): data is PayrollTimelineChangeNoticePeriodDuringProbation => {
  if (!data) {
    return false
  }
  return assertFields(data, [
    'notice_period_during_probation',
    'notice_period_during_probation_unit',
  ])
}

const assertNoticePeriodAfterProbationFields = (
  data: PayrollTimelineChange | null,
): data is PayrollTimelineChangeNoticePeriodAfterProbation => {
  if (!data) {
    return false
  }
  return assertFields(data, [
    'notice_period_after_probation',
    'notice_period_after_probation_unit',
  ])
}

export const getNoticePeriodString = (data: PayrollTimelineChange | null) => {
  if (assertNoticePeriodDuringProbationFields(data)) {
    return pluralize(
      data.notice_period_during_probation_unit,
      data.notice_period_during_probation,
      true,
    )
  }
  if (assertNoticePeriodAfterProbationFields(data)) {
    return pluralize(
      data.notice_period_after_probation_unit,
      data.notice_period_after_probation,
      true,
    )
  }
  return undefined
}

export const formatNoticePeriodDomain = (change: PayrollTimelineChangeInterface) => {
  const from = getNoticePeriodString(change.from_value)
  const to = getNoticePeriodString(change.to_value)

  if (!from && to) {
    return <AddedValueWidget change={change} value={to} />
  }
  if (from && to) {
    return <UpdatedValueWidget change={change} from={from} to={to} />
  }
  return undefined
}

const assertSignOnBonusFields = (
  data: PayrollTimelineChange | null,
): data is PayrollTimelineChangeSignOnBonus => {
  if (!data) {
    return false
  }
  return assertFields(data, [
    'sign_on_bonus_amount',
    'sign_on_bonus_currency',
    'sign_on_bonus_type',
  ])
}

const getSignOnBonusString = (data: PayrollTimelineChange | null) => {
  if (!assertSignOnBonusFields(data)) {
    return undefined
  }
  if (data.sign_on_bonus_type === 'No Bonus') {
    return data.sign_on_bonus_type
  }
  if (!data.sign_on_bonus_amount || !data.sign_on_bonus_currency) {
    return undefined
  }
  return `${formatMoneyWithCode(
    data.sign_on_bonus_amount,
    data.sign_on_bonus_currency,
  )} (${data.sign_on_bonus_type})`
}

export const formatSignOnBonusDomain = (change: PayrollTimelineChangeInterface) => {
  const from = getSignOnBonusString(change.from_value)
  const to = getSignOnBonusString(change.to_value)

  if (!from && to) {
    return <AddedValueWidget change={change} value={to} />
  }
  if (from && to) {
    return <UpdatedValueWidget change={change} from={from} to={to} />
  }
  return undefined
}

const assertSeniorityFields = (
  data: PayrollTimelineChange | null,
): data is PayrollTimelineChangeSeniority => {
  if (!data) {
    return false
  }
  return assertFields(data, ['seniority', 'specialisation_seniority_sublevel'])
}

const getSeniorityString = (data: PayrollTimelineChange | null) => {
  if (!assertSeniorityFields(data)) {
    return undefined
  }
  return `${data.seniority} (${data.specialisation_seniority_sublevel})`
}

export const formatSeniorityDomain = (change: PayrollTimelineChangeInterface) => {
  const from = getSeniorityString(change.from_value)
  const to = getSeniorityString(change.to_value)

  if (!from && to) {
    return <AddedValueWidget change={change} value={to} />
  }
  if (from && to) {
    return <UpdatedValueWidget change={change} from={from} to={to} />
  }
  return undefined
}

const assertNewContractDomainFields = (
  data: PayrollTimelineChange,
): data is PayrollTimelineChangeContractFull => {
  return assertFields<PayrollTimelineChangeContractFull>(data, [
    'approval_status',
    'company_entity',
    'contract_status',
    'contract_term',
    'contract_type',
    'end_date',
    'full_time_equivalent',
    'inactivity_end_date',
    'inactivity_reason',
    'inactivity_start_date',
    'location',
    'notice_period_after_probation',
    'notice_period_after_probation_unit',
    'notice_period_during_probation',
    'notice_period_during_probation_unit',
    'position',
    'salary_amount',
    'salary_currency',
    'salary_payment_frequency',
    'salary_time_unit',
    'seniority',
    'sign_on_bonus_amount',
    'sign_on_bonus_currency',
    'sign_on_bonus_type',
    'specialisation',
    'specialisation_seniority_sublevel',
    'start_date',
    'title',
    'weekly_working_hours',
  ])
}

type FieldPreviewProps = {
  name: string
  value: string | React.ReactNode
}
const FieldPreview = ({ name, value }: FieldPreviewProps) => (
  <Flex justifyContent="space-between" gap="s-24">
    <Text fontWeight={500} color={Token.color.greyTone50}>
      {name}
    </Text>
    {typeof value === 'string' ? <Text>{value}</Text> : value}
  </Flex>
)

export const formatNewContractDomain = (change: PayrollTimelineChangeInterface) => {
  const isContractAdded =
    // this combination of domain & category is reserved by BE for creating a new contract only;
    // otherwise, changes will come individually one by one within other contract domains
    // e.g. contract_status, contract_type, contract_term, etc.
    change.domain_name === 'contract' && change.domain_category === 'contract'

  if (!isContractAdded || !assertNewContractDomainFields(change.to_value)) {
    return undefined
  }
  const data = change.to_value

  return (
    <MultipleChangesCellWrapper change={change} customTitle={`New contract added`}>
      <VStack space="s-16" py="s-8">
        <FieldPreview name="Contract type" value={data.contract_type} />
        <FieldPreview name="Contract term" value={data.contract_term} />
        <FieldPreview name="Specialisation" value={data.specialisation} />
        <FieldPreview name="Seniority" value={getSeniorityString(data)} />
        <FieldPreview name="Location" value={data.location} />
        <FieldPreview
          name="Salary"
          value={
            <VStack align="end">
              <Text>{getSalaryValue(data)}</Text>
              <Text>{getSalaryFrequency(data.salary_payment_frequency)}</Text>
            </VStack>
          }
        />
        <FieldPreview name="Sign on bonus" value={getSignOnBonusString(data)} />
        <FieldPreview name="Weekly working hours" value={data.weekly_working_hours} />
        <FieldPreview
          name="Notice period during probation"
          value={getNoticePeriodString(data)}
        />
        <FieldPreview
          name="Notice period after probation"
          value={getNoticePeriodString(data)}
        />
        <FieldPreview name="Start date" value={formatWithoutTimezone(data.start_date)} />
      </VStack>
    </MultipleChangesCellWrapper>
  )
}

const assertTimeOffDomainFields = (
  data: PayrollTimelineChange,
): data is PayrollTimelineChangeTimeOffRequest => {
  return assertFields(data, [
    'duration',
    'end_date_time',
    'from_date_time',
    'from_time_period',
    'start_date_time',
    'to_date_time',
    'to_time_period',
    'total_duration',
    'unit',
  ])
}

export const formatTimeOffDomain = (change: PayrollTimelineChangeInterface) => {
  if (!isEmpty(change.from_value) || !assertTimeOffDomainFields(change.to_value)) {
    return undefined
  }
  const timeOffData = change.to_value
  const fromDateTime = formatDate(timeOffData.from_date_time, undefined, {
    hideSameYear: true,
  })
  const toDateTime = formatWithoutTimezone(timeOffData.to_date_time)

  return (
    <MultipleChangesCellWrapper
      change={change}
      customTitle="New time-off request created"
    >
      <Text>
        From {fromDateTime} ({timeOffData.from_time_period}) to {toDateTime} (
        {timeOffData.to_time_period})
      </Text>
    </MultipleChangesCellWrapper>
  )
}
