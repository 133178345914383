import { SelectOptionType, HStack, Avatar, Text } from '@revolut/ui-kit'
import { OptionInterface } from '@src/interfaces/selectors'
import React from 'react'
import { getInitials } from '@src/utils/employees'

interface OptionWithAvatarProps<T = OptionInterface> {
  option: SelectOptionType<T>
}

export const OptionWithAvatar = ({ option }: OptionWithAvatarProps) => {
  return (
    <>
      <HStack gap="s-8" align="center">
        <Avatar
          image={option.value?.avatar}
          size={24}
          label={option.value?.name ? getInitials(option.value?.name) : undefined}
        />
        <Text>{option.value?.name}</Text>
      </HStack>
    </>
  )
}
