import React from 'react'

import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { PayrollTimelineEventInterface } from '@src/interfaces/payrollV2'
import { formatWithoutTimezone } from '@src/utils/format'
import { formatSnakeCase } from '@src/utils/string'
import { selectorKeys } from '../api'

export const payCycleTimelineEmployeeNameColumn: ColumnInterface<PayrollTimelineEventInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'content.employee.id',
    dataPoint: 'content.employee.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Employee name',
    insert: ({ data }) => <UserWithAvatar {...data.content.employee} />,
  }

export const payCycleTimelineEffectiveDateTimeColumn: ColumnInterface<PayrollTimelineEventInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'effective_date_time',
    dataPoint: 'content.employee.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Effective date time',
    insert: ({ data }) => formatWithoutTimezone(data.effective_date_time, true),
  }

export const payCycleTimelineCategoryColumn: ColumnInterface<PayrollTimelineEventInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'content.domain_category',
    dataPoint: 'content.domain_category',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Category',
    insert: ({ data }) => formatSnakeCase(data.content.domain_category),
  }

export const payCycleTimelineDomainColumn: ColumnInterface<PayrollTimelineEventInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'content.domain_name',
    dataPoint: 'content.domain_name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Domain',
    insert: ({ data }) => formatSnakeCase(data.content.domain_name),
  }
