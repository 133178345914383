import React from 'react'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { IdAndName } from '@src/interfaces'
import {
  PayCycleInterface,
  PayCycleStatus,
  PAYROLL_ISSUES_LEVEL_CRITICAL,
  PayrollTimelineChange,
  PayrollTimelineChangeInterface,
  PayrollTimelineEventInterface,
} from '@src/interfaces/payrollV2'
import { formatDate, formatWithoutTimezone } from '@src/utils/format'
import { formatSnakeCase } from '@src/utils/string'
import { useTimelineFilters } from '../TabChanges/common'
import { domainNameToFieldsConfig } from './domainToGenericField'

export type RouteParams = { id: string }

export type CycleSelectorOptionsData = {
  options: CycleOption[]
  isLoading: boolean
}

export type CycleOption = IdAndName & {
  statusId: PayCycleStatus
}

export type CommonTabProps = {
  data: PayCycleInterface
  selectedCycle: CycleOption | undefined
  setSelectedCycle: (newCycle: CycleOption) => void
  cycleSelectorOptions: CycleOption[]
  isCycleSelectorLoading: boolean
  timelineFilters: ReturnType<typeof useTimelineFilters>
}

export type FieldConfigType = 'date' | 'dateTime' | 'snakeCase' | 'idAndName'
type FieldRawValue = IdAndName | string | number | null | undefined

export type FieldConfig = {
  path: string
  label?: string
  type?: FieldConfigType | undefined
  renderField?: (data: FieldRawValue) => React.ReactNode
}

const typeToFormatter: Record<FieldConfigType, (data: FieldRawValue) => React.ReactNode> =
  {
    date: d => (d ? formatDate(String(d)) : ''),
    dateTime: d => (d ? formatWithoutTimezone(String(d), true) : ''),
    snakeCase: id => (id ? formatSnakeCase(String(id)) : ''),
    idAndName: d => {
      if (!d) {
        return ''
      }
      return typeof d === 'object' && 'name' in d ? d.name : ''
    },
  }

const isConfig = (field: FieldConfig | string): field is FieldConfig =>
  typeof field === 'object' && 'path' in field

const parseFieldConfig = (fieldMetadata: FieldConfig | string): FieldConfig => {
  const path = isConfig(fieldMetadata) ? fieldMetadata.path : fieldMetadata
  const renderField = isConfig(fieldMetadata) ? fieldMetadata.renderField : undefined

  return {
    path,
    type: isConfig(fieldMetadata) ? fieldMetadata.type : undefined,
    label:
      isConfig(fieldMetadata) && fieldMetadata.label
        ? fieldMetadata.label
        : formatSnakeCase(path.split('.')[0]),
    renderField,
  }
}

const renderField = (change: PayrollTimelineChange | null, fieldConf: FieldConfig) => {
  const { path: fieldPath, type: fieldType } = fieldConf

  const formatFn = fieldType ? typeToFormatter[fieldType] : undefined
  const renderFn = isConfig(fieldConf) ? fieldConf.renderField : undefined

  const value = get(change, fieldPath)

  if (formatFn?.(value)) {
    return { value, result: formatFn(value) }
  }
  if (renderFn?.(value)) {
    return { value, result: renderFn(value) }
  }
  return { value, result: value }
}

const parseNum = (value: FieldRawValue) => {
  if (typeof value === 'number') {
    return value
  }
  if (!isNaN(Number(value))) {
    return Number(value)
  }
  return undefined
}

export type ChangeType =
  | 'none'
  | 'create'
  | 'increase'
  | 'decrease'
  | 'error'
  | 'warning'
  | 'unknown'
const compareIfPossible = (left: FieldRawValue, right: FieldRawValue): ChangeType => {
  if (left === right) {
    return 'none'
  }
  if (left === undefined && (Boolean(right) || right === null)) {
    return 'create'
  }
  const leftNum = parseNum(left)
  const rightNum = parseNum(right)

  if (leftNum && rightNum) {
    return leftNum < rightNum ? 'increase' : leftNum > rightNum ? 'decrease' : 'unknown'
  }
  return 'unknown'
}

export type ParsedDomainFieldChanges = {
  from: React.ReactNode
  to: React.ReactNode
  label: string | undefined
  changeType: ChangeType
}
export const parseDomainFieldChanges = (
  data: PayrollTimelineChangeInterface,
  fieldMetadata: FieldConfig | string,
): ParsedDomainFieldChanges => {
  const fieldConf = parseFieldConfig(fieldMetadata)
  const renderedFrom = renderField(data.from_value, fieldConf)
  const renderedTo = renderField(data.to_value, fieldConf)
  const changeType = compareIfPossible(renderedFrom.value, renderedTo.value)
  const fieldIssue = get(data.issues, fieldConf.path)

  if (fieldIssue) {
    return {
      from: undefined,
      to: fieldIssue,
      label: fieldConf.label,
      changeType:
        data.issues_level.id === PAYROLL_ISSUES_LEVEL_CRITICAL ? 'error' : 'warning',
    }
  }
  return {
    from: renderedFrom.result,
    to: renderedTo.result,
    label: fieldConf.label,
    changeType,
  }
}

export const getFieldsChanges = (data: PayrollTimelineEventInterface) => {
  const fields = domainNameToFieldsConfig[data.content.domain_name]?.fields || []
  let res = []

  for (let i = 0; i < fields.length; i++) {
    const parsedChanges = parseDomainFieldChanges(data.content, fields[i])
    if (parsedChanges.changeType !== 'none' || !isEmpty(data.content.issues)) {
      res.push(parsedChanges)
    }
  }
  return res
}
