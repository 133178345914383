import { useState } from 'react'
import format from 'date-fns/format'

import { FilterByInterface } from '@src/interfaces/data'
import { PayCycleInterface } from '@src/interfaces/payrollV2'
import { OptionInterface } from '@src/interfaces/selectors'
import { useQuery } from '@src/utils/queryParamsHooks'

export const TIMELINE_FROM_DATE_KEY = 'from_date'
export const TIMELINE_TO_DATE_KEY = 'to_date'
export const TIMELINE_EVENT_TYPE_KEY = 'payroll_events'

export type QueryParams = Partial<{
  [TIMELINE_FROM_DATE_KEY]: string
  [TIMELINE_TO_DATE_KEY]: string
  [TIMELINE_EVENT_TYPE_KEY]: string
}>

export const EVENT_TYPES_OPTIONS = [
  { id: 'data_change', name: 'Change' },
  { id: 'hire', name: 'Hire' },
  { id: 'termination', name: 'Termination' },
]

export const initDateFilterFromQuery = (query: QueryParams) => {
  const queryFrom = query[TIMELINE_FROM_DATE_KEY]
  const queryTo = query[TIMELINE_TO_DATE_KEY]

  if (!queryFrom || !queryTo) {
    return []
  }
  return [
    { id: queryFrom, name: queryFrom },
    { id: queryTo, name: queryTo },
  ]
}

const initEventTypesFilterFromQuery = (query: QueryParams) => {
  const eventTypes = query[TIMELINE_EVENT_TYPE_KEY]

  if (!eventTypes) {
    return []
  }
  return eventTypes
    .split(',')
    .map(id => {
      return EVENT_TYPES_OPTIONS.find(option => option.id === id)
    })
    .filter(Boolean)
}

const getDateFilterValue = (date: unknown, columnName: string): FilterByInterface[] => {
  if (typeof date !== 'string') {
    return []
  }
  const formattedDate = format(new Date(date), 'yyyy-MM-dd')
  return [{ columnName, filters: [{ id: formattedDate, name: formattedDate }] }]
}

export const useTimelineFilters = (data?: PayCycleInterface) => {
  const { query } = useQuery()

  const [datesRangeFilter, setDatesRangeFilter] = useState<
    OptionInterface<string | number>[]
  >(initDateFilterFromQuery(query))
  const [eventTypesFilter, setEventTypesFilter] = useState<
    OptionInterface<string | number>[]
  >(initEventTypesFilterFromQuery(query))

  const startDate = data?.start_date ? new Date(data.start_date) : new Date()
  const endDate = data?.end_date ? new Date(data.end_date) : new Date()

  const cycleStartDate = format(startDate, 'yyyy-MM-dd')
  const cycleEndDate = format(endDate, 'yyyy-MM-dd')
  const initFilters = [
    {
      columnName: TIMELINE_FROM_DATE_KEY,
      filters: [{ id: cycleStartDate, name: cycleStartDate }],
    },
    {
      columnName: TIMELINE_TO_DATE_KEY,
      filters: [{ id: cycleEndDate, name: cycleEndDate }],
    },
  ]

  const filterFrom = getDateFilterValue(datesRangeFilter[0]?.id, TIMELINE_FROM_DATE_KEY)
  const filterTo = getDateFilterValue(datesRangeFilter[1]?.id, TIMELINE_TO_DATE_KEY)
  const filterByDatesRange =
    filterFrom.length && filterTo.length ? [...filterFrom, ...filterTo] : initFilters
  const eventTypesFilterStr = eventTypesFilter?.map(f => f.id).join(',')
  const filterByEventType = [
    {
      columnName: TIMELINE_EVENT_TYPE_KEY,
      filters: eventTypesFilterStr
        ? [{ id: eventTypesFilterStr, name: eventTypesFilterStr }]
        : [],
    },
  ]

  return {
    filterByDatesRange,
    datesRangeFilter,
    setDatesRangeFilter,
    filterByEventType,
    eventTypesFilter,
    eventTypesFilterStr,
    setEventTypesFilter,
  }
}
